import Checkbox from "src/components/Interface/inputs/Checkbox";
import styles from "../Application.module.css";
import Input from "src/components/Interface/inputs/Input";
import Select from "src/components/Interface/inputs/Select";
import ToggleButton from "src/components/Interface/inputs/ToggleButton";
import ControlButtons from "../subcomponents/ControlButtons";
import { useForm } from "src/contexts/Form";
import { validateTFN } from "src/utilities/validation";
import { useCallback, useEffect, useMemo } from "react";
import globalStyles from "src/Global.module.css";

const TaxDetails = ({ setTab, tab }) => {
  const { setData, data } = useForm();

  const updateTaxDeclaration = useCallback(
    (val, name) => {
      setData((prev) => ({ ...prev, taxDeclaration: { ...prev.taxDeclaration, [name]: val } }));
    },
    [setData]
  );

  const hideTaxDetails = useMemo(() => data?.incomeType === "NONEMPLOYEE", [data?.incomeType]);

  useEffect(() => {
    if (hideTaxDetails) {
      updateTaxDeclaration(false, "taxFreeThresholdClaimed");
    }
  }, [hideTaxDetails, updateTaxDeclaration]);

  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["form-container"]}>
        <p className={styles["content-heading"]}>Tax Details</p>
        {!hideTaxDetails && (
          <div className={styles["form-tax-flex"]}>
            <div className={globalStyles["form-input-container"]}>
              <Input
                type="text"
                name={"taxFileNumber"}
                label={"Tax File Number (TFN)"}
                validation={validateTFN}
                placeholder={"226799598"}
                maxLength={9}
                onChange={(val) => {
                  setData((prev) => ({
                    ...prev,
                    tFNExemptionType: null,
                    taxDeclaration: { ...prev.taxDeclaration, tFNExemptionType: null },
                  }));
                  updateTaxDeclaration(val, "taxFileNumber");
                }}
              />
            </div>
            <h4>OR</h4>
            <div className={globalStyles["form-input-container"]}>
              <Select
                options={[
                  { label: "No TFN", value: "NOTQUOTED" },
                  { label: "Awaiting TFN", value: "PENDING" },
                  { label: "Pensioner", value: "PENSIONER" },
                  { label: "Under 18", value: "UNDER18" },
                ]}
                label={"TFN Exemption"}
                placeholder={"select"}
                name={"tFNExemptionType"}
                onChange={(val) => {
                  setData((prev) => ({
                    ...prev,
                    taxFileNumber: null,
                    taxDeclaration: { ...prev.taxDeclaration, taxFileNumber: null },
                  }));
                  updateTaxDeclaration(val, "tFNExemptionType");
                }}
              />
            </div>
          </div>
        )}

        <div className={globalStyles["form-input-container"]}>
          <ToggleButton
            name={"residencyStatus"}
            label={"Select residency status for tax purposes"}
            buttons={[
              { label: "Australian Resident", value: "AUSTRALIANRESIDENT" },
              { label: "Foreign Resident", value: "FOREIGNRESIDENT" },
            ]}
            mandatory
            onChange={(val) => {
              setData((prev) =>
                val === "AUSTRALIANRESIDENT"
                  ? {
                      ...prev,
                      australianResidentForTaxPurposes: true,
                      taxDeclaration: { ...prev.taxDeclaration, australianResidentForTaxPurposes: true },
                    }
                  : {
                      ...prev,
                      australianResidentForTaxPurposes: false,
                      taxFreeThresholdClaimed: false,
                      taxDeclaration: {
                        ...prev.taxDeclaration,
                        australianResidentForTaxPurposes: false,
                        taxFreeThresholdClaimed: false,
                      },
                    }
              );

              updateTaxDeclaration(val, "residencyStatus");
            }}
          />
        </div>
        {hideTaxDetails && (
          <div className={globalStyles["form-input-container"]}>
            <Input
              type="text"
              name={"ABN"}
              label={"ABN"}
              mandatory
              placeholder={"Australian business Number"}
              onChange={(val) => updateTaxDeclaration(val, "ABN")}
              mb={"40px"}
            />
          </div>
        )}
        {!hideTaxDetails && (
          <div className={globalStyles["form-flex-container"]}>
            <div className={globalStyles["form-input-container"]}>
              <Input
                type="number"
                name={"taxOffsetEstimatedAmount"}
                label={"Other tax offset claimed"}
                placeholder={"0.00"}
                onChange={(val) => updateTaxDeclaration(val, "taxOffsetEstimatedAmount")}
              />
            </div>
            <div className={globalStyles["form-input-container"]}>
              <Input
                type="number"
                name={"upwardVariationTaxWithholdingAmount"}
                label={"Increase amount of tax withheld"}
                placeholder={"0.00"}
                onChange={(val) => updateTaxDeclaration(val, "upwardVariationTaxWithholdingAmount")}
              />
            </div>
          </div>
        )}
        {!hideTaxDetails && (
          <div className={globalStyles["form-input-container"]}>
            <Checkbox
              mb={"40px"}
              name={"hasLoanOrStudentDebt"}
              label={"Do you have study or training loans?"}
              onChange={(val) => updateTaxDeclaration(val, "hasLoanOrStudentDebt")}
            />
          </div>
        )}
        {data.residencyStatus !== "FOREIGNRESIDENT" && !hideTaxDetails && (
          <div className={globalStyles["form-input-container"]}>
            <Checkbox
              mb={"40px"}
              name={"taxFreeThresholdClaimed"}
              label={"Would you like to claim the tax free threshold?"}
              onChange={(val) => updateTaxDeclaration(val, "taxFreeThresholdClaimed")}
            />
          </div>
        )}
        {!hideTaxDetails && (
          <div className={globalStyles["form-input-container"]}>
            <Checkbox
              mb={"40px"}
              name={"eligibleToReceiveLeaveLoading"}
              label={"Are you eligible for leave loading?"}
              onChange={(val) => updateTaxDeclaration(val, "eligibleToReceiveLeaveLoading")}
            />
          </div>
        )}
      </div>
      <ControlButtons setTab={setTab} tab={tab} />
    </div>
  );
};

export default TaxDetails;
