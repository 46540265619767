import styles from "../EmployerApplication.module.css";
import Input from "src/components/Interface/inputs/Input";
import Select from "src/components/Interface/inputs/Select";
import Date from "src/components/Interface/inputs/Date";
import CheckboxDisplay from "src/components/Interface/inputs/CheckboxDisplay";
import { useCallback, useEffect, useState } from "react";
import { customFetch } from "src/utilities/http";
import globalStyles from "src/Global.module.css";
import useWindowSize from "src/hooks/useWindowSize";
const EmployeeFields = ({ application }) => {
  const [availableFunds, setAvailableFunds] = useState([]);
  const { screen } = useWindowSize();

  const getSuperFunds = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const organisationID = urlParams.get("organisationID");

    const res = await customFetch({
      node: "xero-node",
      method: "GET_SUPERFUNDS",
      data: { organisationID },
    });

    setAvailableFunds(res.data);
  }, []);

  useEffect(() => {
    getSuperFunds();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles["emp-container"]}>
      <div className={styles["form-heading"]}>
        <h2>Employee Fields</h2>
        {screen !== "mobile" && <p>These fields are only to be completed by the employee</p>}
      </div>
      <div className={styles["form-content"]}>
        <div className={styles["form-content-half"]}>
          {/* Personal Details */}
          <div className={styles["block"]}>
            <p className={styles["block-heading"]}>Personal Details</p>
            <div className={styles["block-content"]}>
              <div className={globalStyles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Select
                    options={["Mr", "Ms", "Mrs", "Dr"]}
                    label={"Title"}
                    value={application?.title}
                    placeholder={"Title"}
                    disabled
                  />
                </div>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    label={"First Name"}
                    value={application?.firstName}
                    placeholder={"First Name"}
                    disabled
                  />
                </div>
              </div>
              <div className={globalStyles["form-input-container"]}>
                <Input
                  type="text"
                  label={"Last Name"}
                  value={application?.lastName}
                  placeholder={"Last Name"}
                  disabled
                />
              </div>
              <div className={globalStyles["form-input-container"]}>
                <Input type="text" label={"Email"} value={application?.email} placeholder={"Email"} disabled />
              </div>
              <div className={globalStyles["form-input-container"]}>
                <Input type="text" label={"Phone"} value={application?.phone} placeholder={"Phone"} disabled />
              </div>
              <div className={globalStyles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Date
                    label={"Date of Birth"}
                    format={"YYYY-MM-DD"}
                    value={application?.dateOfBirth}
                    placeholder={"Date of Birth"}
                    disabled
                  />
                </div>
                <div className={globalStyles["form-input-container"]}>
                  <Select
                    options={[
                      { label: "Male", value: "M" },
                      { label: "Female", value: "F" },
                      { label: "Non-Binary", value: "I" },
                      { label: "Rather Not Specify", value: "N" },
                    ]}
                    label={"Gender"}
                    placeholder={"Gender"}
                    value={application?.gender}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles["block"]}>
            <div className={styles["block-content"]}>
              <div className={globalStyles["form-input-container"]}>
                <Input
                  type="text"
                  label={"Address"}
                  value={application?.homeAddress?.addressLine1}
                  placeholder={"Address Line 1"}
                  disabled
                />
                <Input
                  type="text"
                  label={""}
                  value={application?.homeAddress?.addressLine2}
                  mt={"5px"}
                  placeholder={"Address Line 2"}
                  disabled
                />
              </div>
              <div className={globalStyles["form-input-container"]}>
                <Input
                  type="text"
                  label={"Suburb"}
                  value={application?.homeAddress?.city}
                  placeholder={"Suburb"}
                  disabled
                />
              </div>
              <div className={globalStyles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    label={"Postcode"}
                    value={application?.homeAddress?.postalCode}
                    placeholder={"State"}
                    disabled
                  />
                </div>
                <div className={globalStyles["form-input-container"]}>
                  <Select
                    options={["ACT", "NSW", "QLD", "SA", "TAS", "VIC", "WA"]}
                    label={"State"}
                    placeholder={"select"}
                    value={application?.homeAddress?.region}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------------------------------------------- */}
        </div>
        <div className={styles["form-content-half"]}>
          {/* Tax Details */}
          <div className={styles["block"]}>
            <p className={styles["block-heading"]}>Tax Details</p>
            <div className={styles["block-content"]}>
              <div className={styles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    label={"Tax File Number (TFN)"}
                    value={application?.taxDeclaration?.taxFileNumber}
                    placeholder={"Tax File Number"}
                    disabled
                  />
                </div>
                <div className={globalStyles["form-input-container"]}>
                  <Select
                    options={[
                      { label: "No TFN", value: "NOTQUOTED" },
                      { label: "Awaiting TFN", value: "PENDING" },
                      { label: "Pensioner", value: "PENSIONER" },
                      { label: "Under 18", value: "UNDER18" },
                    ]}
                    label={"TFN Exemption"}
                    placeholder={"select"}
                    value={application?.taxDeclaration?.tFNExemptionType}
                    disabled
                  />
                </div>
              </div>
              <div className={globalStyles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Select
                    options={[
                      { label: "Australian Resident", value: "AUSTRALIANRESIDENT" },
                      { label: "Foreign Resident", value: "FOREIGNRESIDENT" },
                    ]}
                    label={"Residency Status"}
                    placeholder={"select"}
                    value={application?.taxDeclaration?.residencyStatus}
                    disabled
                  />
                </div>

                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    label={"ABN"}
                    inActive
                    value={application?.taxDeclaration?.ABN}
                    placeholder={"Australian Business Number"}
                    disabled
                  />
                </div>
              </div>
              <div className={globalStyles["form-flex-container"]}>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    label={"Other tax offest claimed"}
                    value={application?.taxDeclaration?.taxOffsetEstimatedAmount}
                    placeholder={"0.00"}
                    disabled
                  />
                </div>
                <div className={globalStyles["form-input-container"]}>
                  <Input
                    type="text"
                    label={"Increased amount of tax witheld"}
                    value={application?.taxDeclaration?.upwardVariationTaxWithholdingAmount}
                    placeholder={"0.00"}
                    disabled
                  />
                </div>
              </div>
              <div className={globalStyles["form-input-container"]}>
                <CheckboxDisplay
                  value={application?.taxDeclaration?.hasLoanOrStudentDebt}
                  text={"Do you have study or training loans?"}
                  disabled
                />
                <CheckboxDisplay
                  value={application?.taxDeclaration?.taxFreeThresholdClaimed}
                  text={"Would you like to claim the tax free threshold?"}
                  disabled
                />
                <CheckboxDisplay
                  value={application?.eligibleToReceiveLeaveLoading}
                  text={"Are you eligible for leave loading?"}
                  disabled
                />
              </div>
            </div>
          </div>
          {/* ---------------------------------------------------------- */}
          {/* Bank Details */}
          <div className={styles["block"]}>
            <p className={styles["block-heading"]}>Bank Details</p>
            <div className={styles["block-content"]}>
              {(application?.bankAccounts?.length === 0 || !application?.bankAccounts) && (
                <p className={styles["none-added"]}>No bank accounts added yet.</p>
              )}
              {application?.bankAccounts?.map((account) => {
                return (
                  <div className={styles["bank-account"]} key={account.accountNumber}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Account Name"}
                        value={account.accountName}
                        placeholder={"Account Name"}
                        disabled
                      />
                    </div>
                    <div className={globalStyles["form-flex-container"]}>
                      <div className={globalStyles["form-input-container"]}>
                        <Input type="text" label={"BSB"} value={account.bSB} placeholder={"BSB"} disabled />
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Account Number"}
                          value={account.accountNumber}
                          placeholder={"Account Number"}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Statement Text"}
                        value={account.statementText}
                        placeholder={"Statement Text"}
                        disabled
                      />
                      <CheckboxDisplay value={account.remainder} text={"Is remainder?"} disabled />
                    </div>
                    {!account.remainder && (
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Fixed Amount"}
                          value={account.amount}
                          placeholder={"0.00"}
                          disabled
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {/* ---------------------------------------------------------- */}
          {/* Super Details */}
          <div className={styles["block"]}>
            <p className={styles["block-heading"]}>Super Details</p>
            <div className={styles["block-content"]}>
              {(application?.superMemberships?.length === 0 || !application?.superMemberships) && (
                <p className={styles["none-added"]}>No super memberships added yet.</p>
              )}
              {application?.superMemberships?.map((membership, idx) => {
                return membership.type === "REGULATED" ? (
                  <div className={styles["super-membership"]} key={idx}>
                    <div className={globalStyles["form-input-container"]}>
                      <Select
                        options={availableFunds.map((fund) => {
                          return { label: fund.name, value: fund.superFundID };
                        })}
                        label={"Super Fund"}
                        placeholder={"select"}
                        value={membership.superFundID}
                        disabled
                      />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Employee Number"}
                        value={membership?.employeeNumber}
                        placeholder={"Employee Number"}
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles["super-membership"]} key={idx}>
                    <div className={globalStyles["form-input-container"]}>
                      <Input type="text" label={"ABN"} value={membership?.aBN} placeholder={"ABN"} disabled />
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Account Name"}
                        value={membership?.accountName}
                        placeholder={"Account Name"}
                        disabled
                      />
                    </div>
                    <div className={globalStyles["form-flex-container"]}>
                      <div className={globalStyles["form-input-container"]}>
                        <Input type="text" label={"BSB"} value={membership?.bSB} placeholder={"BSB"} disabled />
                      </div>
                      <div className={globalStyles["form-input-container"]}>
                        <Input
                          type="text"
                          label={"Account Number"}
                          value={membership?.accountNumber}
                          placeholder={"Account Number"}
                          disabled
                        />
                      </div>
                    </div>
                    <div className={globalStyles["form-input-container"]}>
                      <Input
                        type="text"
                        label={"Electronic Service Address"}
                        value={membership?.electronicServiceAddress}
                        placeholder={"Elecrtronic Service Address"}
                        disabled
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* ---------------------------------------------------------- */}
        </div>
      </div>
    </div>
  );
};
export default EmployeeFields;
