import styles from "../Application.module.css";
import globalStyles from "src/Global.module.css";
import { useForm } from "src/contexts/Form";
import ToggleButton from "src/components/Interface/inputs/ToggleButton";
import Select from "src/components/Interface/inputs/Select";
import clsx from "clsx";
import Input from "src/components/Interface/inputs/Input";
import Date from "src/components/Interface/inputs/Date";
import ControlButtons from "../subcomponents/ControlButtons";
import { useCallback, useEffect } from "react";

const EmploymentConfirmation = ({ tab, setTab }) => {
  const { setData, data } = useForm();

  const updateTaxDeclaration = useCallback(
    (val, name) => {
      setData((prev) => ({ ...prev, taxDeclaration: { ...prev.taxDeclaration, [name]: val } }));
    },
    [setData]
  );

  useEffect(() => {
    updateTaxDeclaration(data?.employmentBasis, "employmentBasis");
  }, [data?.employmentBasis, updateTaxDeclaration]);

  console.log(data);
  return (
    <div className={styles["content-wrapper"]}>
      <div className={clsx(styles["form-container"], styles["confirm"])}>
        <p className={styles["content-heading"]}>Employment Details Confirmation</p>
        <div className={globalStyles["form-input-container"]}>
          <ToggleButton
            mt={"40px"}
            mb="80px"
            name={"employmentType"}
            buttons={[
              { label: "Employee", value: "EMPLOYEE" },
              { label: "Contractor", value: "CONTRACTOR" },
            ]}
            inActive
            label={"Employment Type"}
            noHover
          />
        </div>
        <div className={clsx(globalStyles["form-input-container"])}>
          {data.employmentType !== "CONTRACTOR" && (
            <Select
              options={[
                { label: "Full Time", value: "FULLTIME" },
                { label: "Part Time", value: "PARTTIME" },
                { label: "Casual", value: "CASUAL" },
              ]}
              label={"Employment Basis"}
              placeholder={"select"}
              name={"employmentBasis"}
              inActive
              boxShadow
              noHover
            />
          )}
        </div>
        <div className={clsx(globalStyles["form-input-container"])}>
          <Select
            inActive
            options={[
              { label: "Salary & Wages", value: "SALARYANDWAGES" },
              { label: "Closely Held Employees", value: "CLOSELYHELDPAYEES" },
              { label: "Working holiday maker", value: "WORKINGHOLIDAYMAKER" },
              { label: "Labour Hire", value: "LABOURHIRE" },
              { label: "Non-employee", value: "NONEMPLOYEE" },
            ]}
            label={"Income Type"}
            placeholder={"select"}
            name={"incomeType"}
            boxShadow
            noHover
          />
        </div>
        {data.jobTitle && (
          <div className={globalStyles["form-input-container"]}>
            <Input
              type="text"
              name={"jobTitle"}
              label={"Job Title"}
              placeholder={"Headmaster"}
              maxLength={100}
              inActive
              boxShadow
            />
          </div>
        )}
        <div className={globalStyles["form-flex-container"]}>
          {data?.startDate && (
            <div className={globalStyles["form-input-container"]}>
              <Date label={"Start Date"} name={"startDate"} format={"YYYY-MM-DD"} inActive boxShadow />
            </div>
          )}
          {data?.hourlyRate && (
            <div className={clsx(globalStyles["form-input-container"], styles["hourly-rate"])}>
              <p className={styles["currency"]}>$</p>
              <Input type="numeric" name={"hourlyRate"} label={"Hourly Rate"} placeholder={"0.00"} inActive boxShadow />
              <p className={styles["unit"]}>/ hour</p>
            </div>
          )}
        </div>
        {data?.classification && (
          <div className={globalStyles["form-input-container"]}>
            <Input
              type="text"
              name={"classification"}
              label={"Award Classification"}
              placeholder={"MA000014"}
              maxLength={100}
              inActive
              boxShadow
            />
          </div>
        )}
      </div>
      <p className={styles["incorrect-details"]}>
        <span>Are your details incorrect?</span>
        <br />
        Please contact your employer immediately.
      </p>
      <ControlButtons setTab={setTab} tab={tab} />
    </div>
  );
};
export default EmploymentConfirmation;
